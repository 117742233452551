import React from "react"
import Layout from "../components/Layout"

import "../styles/Globals.scss"
import { Helmet } from "react-helmet"


export default function NotFound() {
  
  return (
    <Layout>
      <Helmet>
          <body className="404" />
      </Helmet>
      
      <article className="container">
        <div className="row">
          

        </div>
      </article>
    </Layout>)
}
